import React from "react";
import cloud from '../Resources/img/c5.png'

function Menu() {
    return (
        <div id="menu">
            <nav className="navbar navbar-expand-md fondoMenu">
                <div className="container">
                    <a className="navbar-brand" href="/"><img src={cloud} height="40" alt="logo" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="https://www.facebook.com/" target="_blank" rel="noreferrer"><i className="fa-brands fa-facebook-f"></i></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="https://www.instagram.com" target="_blank"  rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="https://twitter.com/" target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter"></i></a>
                            </li>
                        </ul>
                    </div>
                    <ul className="navbar-nav collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                        <li className="nav-item espa">
                            <a className="nav-link active" href="/">INICIO</a>
                        </li>
                        <li className="nav-item espa">
                            <a className="nav-link active" href="/servicios">SERVICIOS</a>
                        </li>
                        <li className="nav-item espa">
                            <a className="nav-link active" href="/contacto">CONTACTO</a>
                        </li>
                        <li className="nav-item espa">
                            <a className="nav-link active" href="/acerca">ACERCA DE</a>
                        </li>
                    </ul>
                </div>
            </nav>
            <div className="barrita"></div>
        </div>
    )
}
export default Menu;