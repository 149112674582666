import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Inicio from "../Pages/Inicio";
import Contacto from "../Pages/Contacto";
import Servicios from "../Pages/Servicios";
import Acerca from "../Pages/Acerca";
import Enviado from "../Pages/Enviado";

function AppRouters() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Inicio />} />
                <Route path="/contacto" element={<Contacto />} />
                <Route path="/servicios" element={<Servicios />} />
                <Route path="/acerca" element={<Acerca />} />
                <Route path="/contacto/enviado" element={<Enviado />} />
            </Routes>
        </Router>
    )
}

export default AppRouters;