import { useState } from 'react';

import logo from '../Resources/img/c1.png';
import Menu from "../Layouts/Menu";
import Footer from "../Layouts/Footer";
import Whatsapp from "../Components/Whatsapp";

function Contacto() {
    const [name, setName] = useState('');
    const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    var direccion = window.location+'/enviado';

    function sendEmail(e) {
        e.preventDefault();

        if(name === '' || lastname === '' || email === '' || phone === '' || message === ''){
            alert('Llenar todos los campos');
            return;
        }
        console.log('Campos llenos');
    }

    return (
        <div>
            <Menu />
            <div className="container">
                <div className="text-center py-2 mt-5">
                    <img src={logo} alt="logo" width="250" />
                </div>
                <div className="row my-5 py-5">
                    <div className="col-12 col-md-6">
                        <p className="fw-bold text-start fs-4"><i className="fa-solid fa-shield-halved"></i> SERVICIOS Y SOLUCIONES <i className="fa-solid fa-screwdriver-wrench"></i></p>
                        <p className="text-start fs-5">Llena el formulario o llámanos para poder asesórate en tu inversión.</p>
                        <p className="text-start fs-5"><i className="fa-solid fa-envelopes-bulk"></i> cloudsinvinnovate@gmail.com</p>
                        <a href="https://wa.me/50230599707?text=Hola,%20le%20comento%20que%20me%20interesó:%20" className="enlaceWhats" target="_blank" rel="noreferrer"><p className="text-success fw-bold espaFot"><i className="fa-brands fa-whatsapp"></i> &nbsp;&nbsp;+502 3059 9707</p></a>
                    </div>
                    <div className="col-12 col-md-6">
                        <form className="form" action="https://formsubmit.co/cloudsinvinnovate@gmail.com" method="POST" >
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form__group field">
                                        <input type="text" className="form__field" placeholder="Nombres" name="name" id='name' onChange={(e) => setName(e.target.value)} autoComplete="Jhon" value={name} required  />
                                        <label htmlFor="name" className="form__label">Nombres</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form__group field">
                                        <input type="text" id='lastname' className="form__field" placeholder="Apellidos" name="lastname"  onChange={(e) => setLastName(e.target.value)} value={lastname} autoComplete="Pérez" required />
                                        <label htmlFor="lastname" className="form__label">Apellidos</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form__group field">
                                        <input type="email" className="form__field" placeholder="Correo Electrónico" name="email" id='email' required onChange={(e) => setEmail(e.target.value)} autoComplete="example@mail.com" value={email} />
                                        <label htmlFor="email" className="form__label">Correo Electrónico</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form__group field">
                                        <input type="number" className="form__field" placeholder="Teléfono" name="phone" id='phone' required  onChange={(e) => setPhone(e.target.value)} value={phone} autoComplete="2244"/>
                                        <label htmlFor="phone" className="form__label" >Teléfono</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="form__group field">
                                        <textarea className="form__field" rows="3" placeholder="Mensaje" name="message" id='message' onChange={(e) => setMessage(e.target.value)} value={message} required />
                                        <label htmlFor="message" className="form__label">Mensaje</label>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                <button type='submit' className="botonEnviar">Enviar </button>
                            </div>
                            <input type='hidden' name='_next' value={direccion} />
                            <input type="hidden" name="_captcha" value="false"></input>
                        </form>
                    </div>
                </div>
            </div>
            <Whatsapp />
            <Footer />
        </div>
    );
}

export default Contacto;