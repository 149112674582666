import React from "react";
import AppRouter from '../src/Routers/Routers';

import 'bootstrap/dist/css/bootstrap.css';
import '../src/Resources/fontawesome/css/all.css';
import '../src/Resources/fontawesome/css/solid.css';
import '../src/Resources/fontawesome/css/brands.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'aos/dist/aos.css';
import './App.css';

function App() {
  return (
    <AppRouter />
  );
}

export default App;