import React, { Component } from "react";

import logo from '../Resources/img/c1.png';
import Menu from "../Layouts/Menu";
import Footer from "../Layouts/Footer";
import Whatsapp from "../Components/Whatsapp";

import fondo5 from '../Resources/img/fondo5.jpg';
import fondo6 from '../Resources/img/fondo6.jpg';
import fondo7 from '../Resources/img/fondo7.jpg';
import fondo8 from '../Resources/img/fondo8.jpg';

import servicio1 from '../Resources/img/s1.jpg';
import servicio2 from '../Resources/img/s2.jpg';
import servicio3 from '../Resources/img/s3.jpg';
import servicio4 from '../Resources/img/s4.jpg';
import servicio5 from '../Resources/img/s5.jpg';
import servicio6 from '../Resources/img/s6.jpg';
import servicio7 from '../Resources/img/s7.jpg';
import servicio9 from '../Resources/img/s9.jpg';
import servicio10 from '../Resources/img/s10.jpg';


class Servicios extends Component {
    render() {
        return (
            <div>
                <Menu />
                <div id="fondeServices">
                    <div className="container">
                        <div className="text-center py-2 mt-3 mb-md-4">
                            <img src={logo} alt="logo" width="250" />
                        </div>
                        <div className="text-center py-5 text-center">
                            <p className="fw-bold fs-1">Nuestros Servicios</p>
                            <p className="fw-bold fs-4">Servicios y Soluciones</p>
                        </div>
                        <div className="container row">
                            <div className="col-12 col-md-6">
                                <div className="row">
                                    <div className="col-auto fs-4 fw-bold"><i className="fa-solid fa-clipboard-list"></i></div>
                                    <div className="col-auto fs-4 fw-bold"><span className="badge rounded-pill bg-primary ms-2 me-2">&nbsp;&nbsp;ADMINISTRACIÓN&nbsp;&nbsp;</span></div>
                                </div>
                                <p className="fs-5 mt-2"><i className="fa-regular fa-circle-check"></i>  &nbsp; Administración de cámaras de video seguridad</p>
                                <p className="fs-5"><i className="fa-regular fa-circle-check"></i>  &nbsp; Administración de del tráfico IP / entrada - salida</p>
                                <p className="fs-5"><i className="fa-regular fa-circle-check"></i>  &nbsp; Monitoreo 24/7</p>
                                <p className="fs-5"><i className="fa-regular fa-circle-check"></i>  &nbsp; Velocidad alta con mayor disponibilidad</p>
                                <p className="fs-5"><i className="fa-regular fa-circle-check"></i>  &nbsp; Infraestructura sobre MPLS y Microonda</p>
                                <p className="fs-5"><i className="fa-regular fa-circle-check"></i>  &nbsp; Enlace de Datos</p>
                                <p className="fs-5"><i className="fa-regular fa-circle-check"></i>  &nbsp; Redundancia de Datos</p> 
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="row">
                                    <div className="col-auto fs-4 fw-bold"><i className="fa-solid fa-handshake"></i> <i className="fa-solid fa-gears"></i></div>
                                    <div className="col-auto fs-4 fw-bold"><span className="badge rounded-pill bg-primary ms-2 me-2">&nbsp;&nbsp;SERVICIOS&nbsp;&nbsp;</span></div>
                                </div>
                                <p className="fs-5 mt-2"><i className="fa-regular fa-circle-check"></i>  &nbsp; Planificación, Instalación y Configuración de cámaras de video seguridad</p>
                                <p className="fs-5"><i className="fa-regular fa-circle-check"></i>  &nbsp; Instalación, actualización, y configuración de sistemas operativos (Windows, Linux Mac OS)</p>
                                <p className="fs-5"><i className="fa-regular fa-circle-check"></i>  &nbsp; Mantenimiento, instalación y configuración de equipos informáticos</p>
                                <p className="fs-5"><i className="fa-regular fa-circle-check"></i>  &nbsp; Mantenimiento de Servidores y Data Center</p>
                                <p className="fs-5"><i className="fa-regular fa-circle-check"></i>  &nbsp; Planificación e Instalación de Cableado Estructurado</p>
                                <p className="fs-5"><i className="fa-regular fa-circle-check"></i>  &nbsp; Administración y Configuración de Antivirus y Firewall</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container bg-white my-5 pb-5">
                    <div className="row">
                        <div className="col-6 col-md-3 mt-4">
                            <img src={servicio1} className="d-block w-100" alt="fondo 4" />
                            <p className="fs-4 lh-1 text-center fw-bold">Cámaras de Videovigilancia</p>
                            <p className="fs-5 lh-1 text-center">Instalación y Configuración</p>
                        </div>
                        <div className="col-6 col-md-3 mt-4">
                            <img src={servicio2} className="d-block w-100" alt="fondo 4" />
                            <p className="fs-4 lh-1 text-center fw-bold">Redundancia de Datos</p>
                            <p className="fs-5 lh-1 text-center"></p>
                        </div>
                        <div className="col-6 col-md-3 mt-4">
                            <img src={servicio3} className="d-block w-100" alt="fondo 4" />
                            <p className="fs-4 lh-1 text-center fw-bold">Antivirus</p>
                            <p className="fs-5 lh-1 text-center"></p>
                        </div>
                        <div className="col-6 col-md-3 mt-4">
                            <img src={servicio4} className="d-block w-100" alt="fondo 4" />
                            <p className="fs-4 lh-1 text-center fw-bold">Firewall</p>
                            <p className="fs-5 lh-1 text-center"></p>
                        </div>
                        <div className="col-6 col-md-3 mt-4">
                            <img src={servicio5} className="d-block w-100" alt="fondo 4" />
                            <p className="fs-4 lh-1 text-center fw-bold">Cableado Estructurado</p>
                            <p className="fs-5 lh-1 text-center"></p>
                        </div>
                        <div className="col-6 col-md-3 mt-4">
                            <img src={servicio6} className="d-block w-100" alt="fondo 4" />
                            <p className="fs-4 lh-1 text-center fw-bold">Punto Acceso</p>
                            <p className="fs-5 lh-1 text-center"></p>
                        </div>
                        <div className="col-6 col-md-3 mt-4">
                            <img src={servicio7} className="d-block w-100" alt="fondo 4" />
                            <p className="fs-4 lh-1 text-center fw-bold">Enlace de Datos</p>
                            <p className="fs-5 lh-1 text-center"></p>
                        </div>
                        <div className="col-6 col-md-3 mt-4">
                            <img src={servicio9} className="d-block w-100" alt="fondo 4" />
                            <p className="fs-4 lh-1 text-center fw-bold">Soporte Técnico</p>
                            <p className="fs-5 lh-1 text-center"></p>
                        </div>
                        <div className="col-6 col-md-3 mt-4">
                            <img src={servicio10} className="d-block w-100" alt="fondo 4" />
                            <p className="fs-4 lh-1 text-center fw-bold">Redundancia SD-WAN</p>
                            <p className="fs-5 lh-1 text-center"></p>
                        </div>
                    </div>
                </div>
                <div className="cajonAzul2">
                    <p className="text-center text-white fs-2">!Contáctanos, no dudes más¡</p>
                    <div className="text-center mt-4">
                        <button type="button" className="botonCotiza"><a className="nav-link active" href="https://wa.me/50230599707?text=Hola,%20le%20comento%20que%20me%20interesó:%20" target="_blank" rel="noreferrer"><i className="fa-brands fa-whatsapp"></i> Escríbenos ahora mismo</a></button>
                    </div>
                </div>
                <div id="cloudimagenes" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#cloudimagenes" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#cloudimagenes" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#cloudimagenes" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#cloudimagenes" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={fondo5} className="d-block w-100" alt="fondo" />
                        </div>
                        <div className="carousel-item">
                            <img src={fondo6} className="d-block w-100" alt="fondo 2" />
                        </div>
                        <div className="carousel-item">
                            <img src={fondo7} className="d-block w-100" alt="fondo 3" />
                        </div>
                        <div className="carousel-item">
                            <img src={fondo8} className="d-block w-100" alt="fondo 4" />
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#cloudimagenes" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#cloudimagenes" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <Whatsapp />
                <Footer />
            </div>
        )
    }
}

export default Servicios;