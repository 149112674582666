import React from "react";
import Menu from '../Layouts/Menu';
import Footer from "../Layouts/Footer";
import fondo from '../Resources/img/fondo.jpg';
import fondo1 from '../Resources/img/fondo1.jpg';
import fondo2 from '../Resources/img/fondo2.jpg';
import fondo3 from '../Resources/img/fondo3.jpg';
import fondo5 from '../Resources/img/fondo5.jpg';
import fondo6 from '../Resources/img/fondo6.jpg';
import fondo7 from '../Resources/img/fondo7.jpg';
import fondo8 from '../Resources/img/fondo8.jpg';
import logo from '../Resources/img/c1.png';
import joven from '../Resources/img/joven.png';
import m1 from '../Resources/img/m1.png';
import m2 from '../Resources/img/m2.png';
import m3 from '../Resources/img/m13.png';
import m4 from '../Resources/img/m12.png';
import m5 from '../Resources/img/m11.png';
import m6 from '../Resources/img/m10.png';
import m7 from '../Resources/img/m9.png';
import m8 from '../Resources/img/m8.png';
import m9 from '../Resources/img/m7.png';
import m10 from '../Resources/img/m6.png';
import m11 from '../Resources/img/m14.png';
import m12 from '../Resources/img/m15.png';
import m13 from '../Resources/img/m16.png';
import m14 from '../Resources/img/m17.png';
import m15 from '../Resources/img/m18.png';
import m16 from '../Resources/img/m5.png';
import m17 from '../Resources/img/m4.png';
import m18 from '../Resources/img/m3.png';
import m19 from '../Resources/img/cable.png';
import m20 from '../Resources/img/cable2.png';
import $ from 'jquery';

import 'aos/dist/aos.css';
import AOS from 'aos';
import Whatsapp from "../Components/Whatsapp";

function Inicio() {
    AOS.init();
    $(function(){
        $('.ir-arriba').on( "click", function(){
            $('body, html').animate({
                scrollTop: '0px'
            }, 300);
        });

        $(window).on( "scroll", function(){
            if( $(this).scrollTop() > 0 ){
                $('.ir-arriba').slideDown(300);
            } else {
                $('.ir-arriba').slideUp(300);
            }
        });
    });
    return (
        <div>
            <Menu />
            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={fondo} className="d-block w-100" alt="fondo" />
                    </div>
                    <div className="carousel-item">
                        <img src={fondo1} className="d-block w-100" alt="fondo 2" />
                        <div className="carousel-caption d-none d-md-block">
                            <p className="titulo">Cámaras de Seguridad</p>
                            <p className="texto2">Con las mejor tecnología en el mercado</p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={fondo2} className="d-block w-100" alt="fondo 3" />
                        <div className="carousel-caption d-none d-md-block">
                            <p className="titulo">Soporte Técnico</p>
                            <p className="texto2">Equipo especializado para dar soporte</p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={fondo3} className="d-block w-100" alt="fondo 4" />
                        <div className="carousel-caption d-none d-md-block">
                            <p className="titulo">Equipo de Monitoreo</p>
                            <p className="texto2">Instalación y Comfiguración de equipo de Camáras de Seguridad Empresarial</p>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div className="espacioContenidoCentral container">
                <div className="text-center my-4 py-4 my-md-5 py-md-5">
                    <img src={logo} className="img-fluid mt-3" width="300" alt="fondo 2" />
                    <p className="fw-bold fs-3">SERVICIOS Y SOLUCIONES PARA TU NEGOCIO Y/O EMPRESA</p>
                </div>
            </div>
            <div className="container-fluid cajaFondo py-5">
                <div className="row">
                    <div className="col-12 col-md-6 margenImg">
                        <img src={joven} className="img-fluid" width="740" alt="joven" />
                    </div>
                    <div className="col-12 col-md-6 mt-5 mt-lg-0">
                        <div className="row mt-5 pt-5">
                            <div className="col-12 col-md-6">
                                <div className="cajitaServicio">
                                    <p className="tamicono"><i className="fa-solid fa-screwdriver-wrench"></i></p>
                                </div>
                                <p className="text-center tamiTexto">SERVICIOS</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="cajitaServicio">
                                    <p className="tamicono"><i className="fa-regular fa-circle-check"></i></p>
                                </div>
                                <p className="text-center tamiTexto">SOLUCIONES</p>
                            </div>
                        </div>
                        <div className="text-center text-lg-start">
                            <img src={logo} className="img-fluid mt-3" width="300" alt="fondo 2" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="fondo2">
                <div className="container">
                    <p className="text-center fw-bold text-white shadowText ">ANTIVIRUS Y FIREWALL</p>
                    <div className="row mb-4">
                        <div className="col-6 col-md-3 text-center mt-3">
                            <div className="cajaMarca"><img src={m3} className="img-fluid" width="300" alt="fondo 2" /></div>
                        </div>
                        <div className="col-6 col-md-3 text-center mt-3">
                            <div className="cajaMarca"><img src={m4} className="img-fluid" width="300" alt="fondo 2" /></div>
                        </div>
                        <div className="col-6 col-md-3 text-center mt-3">
                            <div className="cajaMarca"><img src={m5} className="img-fluid" width="300" alt="fondo 2" /></div>
                        </div>
                        <div className="col-6 col-md-3 text-center mt-3">
                            <div className="cajaMarca"><img src={m6} className="img-fluid" width="300" alt="fondo 2" /></div>
                        </div>
                        <div className="col-6 col-md-3 text-center mt-3">
                            <div className="cajaMarca"><img src={m7} className="img-fluid" width="300" alt="fondo 2" /></div>
                        </div>
                        <div className="col-6 col-md-3 text-center mt-3">
                            <div className="cajaMarca"><img src={m8} className="img-fluid" width="300" alt="fondo 2" /></div>
                        </div>
                        <div className="col-6 col-md-3 text-center mt-3">
                            <div className="cajaMarca"><img src={m9} className="img-fluid" width="300" alt="fondo 2" /></div>
                        </div>
                        <div className="col-6 col-md-3 text-center mt-3">
                            <div className="cajaMarca"><img src={m10} className="img-fluid" width="300" alt="fondo 2" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fondoImgen">
                <div className="container">
                    <p className="text-center fw-bold text-white shadowText ">EQUIPO DE VIDEOVIGILANCIA</p>
                    <div className="row">
                        <div className="col-0 col-md-5 mt-0 mt-lg-0"></div>
                        <div className="col-12 col-md-7 mt-5 mt-lg-0">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="cajitaServicio2 text-center">
                                        <img src={m1} className="img-fluid" width="300" alt="fondo 2" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="cajitaServicio2 text-center">
                                        <img src={m2} className="img-fluid" width="300" alt="fondo 2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="cloudimagenes" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#cloudimagenes" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#cloudimagenes" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#cloudimagenes" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#cloudimagenes" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={fondo5} className="d-block w-100" alt="fondo" />
                    </div>
                    <div className="carousel-item">
                        <img src={fondo6} className="d-block w-100" alt="fondo 2" />
                    </div>
                    <div className="carousel-item">
                        <img src={fondo7} className="d-block w-100" alt="fondo 3" />
                    </div>
                    <div className="carousel-item">
                        <img src={fondo8} className="d-block w-100" alt="fondo 4" />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#cloudimagenes" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#cloudimagenes" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div className="fondoImgenAccess">
                <div className="container">
                    <p className="text-center fw-bold text-white shadowText ">ACCESS POINT</p>
                    <div className="row">
                        <div className="col-0 col-md-5 mt-0 mt-lg-0"></div>
                        <div className="col-12 col-md-7 mt-5 mt-lg-0">
                            <div className="row mt-1 mt-md-5">
                                <div className="col-6 col-md-4 text-center mt-3">
                                    <div className="cajaMarca"><img src={m11} className="img-fluid" width="300" alt="fondo 2" /></div>
                                </div>
                                <div className="col-6 col-md-4 text-center mt-3">
                                    <div className="cajaMarca"><img src={m12} className="img-fluid" width="300" alt="fondo 2" /></div>
                                </div>
                                <div className="col-6 col-md-4 text-center mt-3">
                                    <div className="cajaMarca"><img src={m13} className="img-fluid" width="300" alt="fondo 2" /></div>
                                </div>
                                <div className="col-6 col-md-4 text-center mt-3">
                                    <div className="cajaMarca"><img src={m14} className="img-fluid" width="300" alt="fondo 2" /></div>
                                </div>
                                <div className="col-6 col-md-4 text-center mt-3">
                                    <div className="cajaMarca"><img src={m15} className="img-fluid" width="300" alt="fondo 2" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center">
                <img src={m20} className="img-fluid" alt="fondo 2" />
            </div>
            <div className="container my-md-5">
                <div className="py-5 my-md-5">
                    <p className="text-center fw-bold text-primary fs-1">CABLEADO ESTRUCTURADO</p>
                    <div className="row mt-1 mt-md-5">
                        <div className="col-6 col-md-4 text-center mt-3">
                            <div className="cajaMarca"><img src={m16} className="img-fluid" width="300" alt="fondo 2" /></div>
                        </div>
                        <div className="col-6 col-md-4 text-center mt-3">
                            <div className="cajaMarca"><img src={m17} className="img-fluid" width="300" alt="fondo 2" /></div>
                        </div>
                        <div className="col-6 col-md-4 text-center mt-3">
                            <div className="cajaMarca"><img src={m18} className="img-fluid" width="300" alt="fondo 2" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center">
                <img src={m19} className="img-fluid" alt="fondo 2" />
            </div>
            <span className="ir-arriba"><i className="fa-solid fa-chevron-up"></i></span>
            <Whatsapp />
            <Footer />
        </div>
    )
}

export default Inicio