import React, { Component } from "react";

import Menu from "../Layouts/Menu";
import Footer from "../Layouts/Footer";
import logo from '../Resources/img/c1.png';

class Enviado extends Component {
    render() {
        return (
            <div>
                <Menu />
                <div className="container mb-5 pb-5">
                    <div className="m-5">
                        <div className="card text-center">
                            <p className="fs-5 mt-5">Su mensaje se ha enviado</p>
                            <p className="fs-3">¡ Exitosamente !</p>
                            <a href="/" className="fs-5 enlaceWhats mb-5">Regresar al Inicio &nbsp; <img src={logo} className="img-fluid mt-3" width="80" alt="fondo 2" /></a>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Enviado;