import React, { Component } from "react";


import Menu from "../Layouts/Menu";
import Footer from "../Layouts/Footer";
import Whatsapp from "../Components/Whatsapp";

class Acerca extends Component {
    render() {
        return (
            <div>
                <Menu />
                <div className="hero">
                <div className="hero__container container mt-5 pt-5">
                    <h1 className="hero__title">Cloudsync Innovate</h1>
                    <p className="hero__paragraph1">Implementación de sistemas de video vigilancia para residencias, empresas, industrias y comercios.</p>
                    <p className="hero__paragraph">Equipo de soporte técnico, Administración de Servicios en la Nube.</p>
                </div>
                </div>
                <div className="container">
                    <div className="text-center py-2 py-md-5 mt-5 mb-4 text-center">
                        <p className="fw-bold fs-1">Acerca de Cloudsync Innovate</p>
                        <div id="conten">
                            <p className="fs-5">Empresa dedicada a implementación de cámaras de video vigilancia, almacenamiento en la nube, soporte técnico y todo lo referente a la tecnología.</p>
                        </div>
                    </div>
                </div>
                <div id="imgFondo2" className="imagenFondo2"></div>
                <Whatsapp />
                <Footer />
            </div>
        )
    }
}

export default Acerca;