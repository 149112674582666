import React, { Component } from "react";

import logo from '../Resources/img/c2.png';

class Footer extends Component {
    render() {
        const fecha = new Date();
        const anoActual = fecha.getFullYear();
        return (
            <div className="footer">
                <footer className="container">
                    <div className="row">
                        <div className="col-12 col-md-3 mt-3">
                            <p className="fw-bold fs-4 text-white" >Contacto</p>
                            <p className="text-white espaFot"><i className="fa-solid fa-location-dot"></i> &nbsp;&nbsp;Guatemala, Guatemala</p>
                            <p className="text-white espaFot"><i className="fa-solid fa-envelopes-bulk"></i> &nbsp;cloudsinvinnovate@gmail.com</p>
                            <a href="https://wa.me/50230599707?text=Hola,%20le%20comento%20que%20me%20interesó:%20" className="text-white enlaceWhats mb-5" target="_blank" rel="noreferrer"><p className="text-white espaFot"><i className="fa-brands fa-whatsapp"></i> &nbsp;&nbsp;+502 3059 9707</p></a>
                            <p className="text-white espaFot"><i className="fa-solid fa-phone"></i> &nbsp;&nbsp;+502 3059 9707</p>
                        </div>
                        <div className="col-12 col-md-5 text-center">
                            <img src={logo} alt="logo" height="150" />
                            <p className="azul3 mt-2">Asesoramiento profesional personalizado</p>
                        </div>
                        <div className="col-12 col-md-2 text-start">
                            <p className="fw-bold fs-4 text-white">Menú</p>
                            <a href="/" className="text-white nav-link active">Incio</a>
                            <a href="/servicios" className="text-white nav-link active">Servicios</a>
                            <a href="/contacto" className="text-white nav-link active">Contacto</a>
                            <a href="/acerca" className="text-white nav-link active">Acerca de</a>
                        </div>
                        <div className="col-12 col-md-2 text-center">
                            <p className="fw-bold fs-4 text-white">Redes Sociales</p>
                            <div className="text-center">
                                <a className="azul3 fs-3 m-2" href="https://www.facebook.com/" target="_blank" rel="noreferrer"><i className="fa-brands fa-facebook"></i></a>
                                <a className="azul3 fs-3 m-2" href="https://www.instagram.com" target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>
                                <a className="azul3 fs-3 m-2" href="https://twitter.com/home" target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter"></i></a>
                                <a className="azul3 fs-3 m-2" href="https://wa.me/50230599707?text=Hola,%20le%20comento%20que%20me%20interesó:%20" target="_blank" rel="noreferrer"><i className="fa-brands fa-whatsapp"></i></a>
                            </div>
                        </div>
                    </div>
                    <p className="text-white pt-5">&#169; {anoActual} Todos los derechos reservados Cloudsync Innovate</p>
                </footer>
            </div>
        )
    }
}

export default Footer;